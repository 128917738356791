import { Directive, ElementRef } from "@angular/core";
import stickybits from "stickybits";

@Directive({
  selector: "[sticky-bit]" // or whatever selector you fancy
})
export class StickybitsDirective {
  constructor(elementRef: ElementRef) {
    try {
      const x = stickybits(elementRef.nativeElement, {
        useStickyClasses: false
      });
      console.log("ok", x);
    } catch (e) {
      console.log("error!", e);
    }
  }
}
